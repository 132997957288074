<template>
  <v-container fluid class="fill-height pa-0 landing-container">

    <!-- Landing page's header -->

    <v-app>
      <v-app-bar class="signout-header px-3" app height="64px">
        <v-toolbar-title>
          <img src="/signherologoW.svg" class="hidden-xs-only signhero-logo" height="50"/>
          <img src="/SHicon.svg" class="hidden-sm-and-up signhero-icon" height="50"/>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn class="landing-nav-btn" color="white" text @click.stop="drawer = !drawer" :ripple="false">
              <v-icon>
                mdi-menu
              </v-icon>
              <span class="hidden-sm-and-down">{{ $t( 'message.NavigationMenuButton' ) }}</span>
            </v-btn>
            <v-divider class="menu-divider" vertical></v-divider>
            <div class="language-select-landing">
              <v-btn :ripple="false" class="landing-nav-btn" color="white" text @click="showLanguageSetting">
                <v-icon>
                  mdi-earth
                </v-icon>
                <span class="hidden-sm-and-down">&nbsp;{{ $t( 'message.ThisLocale' ) }}</span>
              </v-btn>
            </div>
            <v-divider class="menu-divider" vertical></v-divider>
            <v-btn class="landing-nav-btn" color="white" text to="/signin" :ripple="false">
              <v-icon>
                mdi-login
              </v-icon>
              <span class="hidden-sm-and-down">{{ $t( 'message.NavigationAccountButton' ) }}</span>
            </v-btn>
            <v-divider class="menu-divider display-xs-none" vertical></v-divider>
            <v-btn :ripple="false" class="landing-nav-btn display-xs-none" color="white" href="#ContactUs" text @click="closeLanguageSetting">
              <v-icon>
                mdi-email-outline
              </v-icon>
              <span class="hidden-sm-and-down">{{ $t( 'message.NavigationContactButton' ) }}</span>
            </v-btn>
          </template>
        </v-menu>
      </v-app-bar>

      <!-- Drawer -->

      <v-navigation-drawer class="right-nav" v-model="drawer" absolute right temporary overlay height="100vh">
        <v-list nav dense>
          <v-list-item-group v-model="group" class="text-right mt-1 ma-2">
            <v-list-item :ripple="false" class="ma-0 pa-3" href="#Introduction" @click="drawer = false">
              <v-list-item-title class="drawer-title" @click="closeLanguageSetting">{{ $t( 'message.NavItemIntroduction' ) }}</v-list-item-title>
            </v-list-item>
            <v-list-item :ripple="false" class="ma-0 pa-3" href="#ChooseEasy" @click="drawer = false">
              <v-list-item-title class="drawer-title" @click="closeLanguageSetting">{{ $t( 'message.NavItemChooseEasy' ) }}</v-list-item-title>
            </v-list-item>
            <v-list-item :ripple="false" class="ma-0 pa-3" href="#Account" @click="drawer = false">
              <v-list-item-title class="drawer-title" @click="closeLanguageSetting">{{ $t( 'message.NavItemAccount' ) }}</v-list-item-title>
            </v-list-item>
            <v-list-item :ripple="false" class="ma-0 pa-3" href="#Pricing" @click="drawer = false">
              <v-list-item-title class="drawer-title" @click="closeLanguageSetting">{{ $t( 'message.TitlePricing' ) }}</v-list-item-title>
            </v-list-item>
            <v-list-item class="ma-0 pa-3" href="#News" @click="drawer = false" :ripple="false">
              <v-list-item-title class="drawer-title">{{ $t( 'message.TitleNews' ) }}</v-list-item-title>
            </v-list-item>
            <v-list-item :ripple="false" class="ma-0 pa-3" href="#APIs" @click="drawer = false">
              <v-list-item-title class="drawer-title" @click="closeLanguageSetting">{{ $t( 'message.NavItemAPIsIntegrations' ) }}</v-list-item-title>
            </v-list-item>
            <v-list-item :ripple="false" class="ma-0 pa-3" href="#Doubts" @click="drawer = false">
              <v-list-item-title class="drawer-title" @click="closeLanguageSetting">{{ $t( 'message.NavItemDoubtsWorries' ) }}</v-list-item-title>
            </v-list-item>
            <v-list-item :ripple="false" class="ma-0 pa-3" href="#ThisIsUs" @click="drawer = false">
              <v-list-item-title class="drawer-title" @click="closeLanguageSetting">{{ $t( 'message.NavItemThisIsUs' ) }}</v-list-item-title>
            </v-list-item>
            <v-list-item :ripple="false" class="ma-0 pa-3" href="#ContactUs" @click="drawer = false">
              <v-list-item-title class="drawer-title" @click="closeLanguageSetting">{{ $t( 'message.NavItemContactUs' ) }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

    </v-app>

    <v-row class="fill-height" no-gutters>
      <v-col v-if="!choose_language_setting">

        <!--section1 Join now -->

        <a id="Introduction" class="sh-sub-anchor"></a>
        <div class="sh-sub-banner sh-fp-intro">
          <div class="container sh-sub-floatingContainer maxWidth-1140">
            <h1 class="slogan">{{ $t( 'message.MainSlogan' ) }}</h1>
            <p class="mb-0 sh-fp-intro">{{ $t( 'message.SubSloganPart1' ) }}</p>
            <p class="sh-fp-intro margin-above-joinNow">{{ $t( 'message.SubSloganPart2' ) }}</p>
            <div class="landing-btn">
              <v-btn class="white--text text-none" to="/signup">
                {{ $t( 'message.GoToRegister' ) }}
              </v-btn>
            </div>
          </div>
        </div>

        <!--section2 Choose easy -->

        <a id="ChooseEasy" class="sh-sub-anchor sh-sub-secondAnchor"></a>

        <!--OLD. Used by de, es, fr, nl, pl, th-->
        <div class="choose-lazy-section sh-sub-sectionWhite sh-sub-shiftTop" v-if="!['fi', 'sv', 'en'].includes( curLocale )">
          <div class="sh-sub-diagonalTop"></div>
          <div class="pt-0 container sh-sub-sectionContainer maxWidth-1140">
            <h2 class="font-weight-light">{{ $t( 'message.TitleLazy' ) }}</h2>
            <v-row>
              <v-col>
                <p>{{ $t( 'message.TextLazyLine1' ) }}<br>
                  {{ $t( 'message.TextLazyLine2' ) }}<br>
                  {{ $t( 'message.TextLazyLine3' ) }}</p>
              </v-col>
            </v-row>
            <div class="eSigning-slogan">
              {{ $t( 'message.MetricsTitle' ) }}
            </div>
            <v-row class="mt-3">
              <v-col class="weekly-metrics" cols="12" sm="4">
                {{ $t( 'message.Metrics1' ) }}
              </v-col>
              <v-col class="weekly-metrics" cols="12" sm="4">
                {{ $t( 'message.Metrics2' ) }}
              </v-col>
              <v-col class="weekly-metrics" cols="12" sm="4">
                {{ $t( 'message.Metrics3' ) }}
              </v-col>
            </v-row>
          </div>
          <div class="sh-sub-diagonalTBottom"></div>
        </div>

        <!--NEW. Used by en, fi, sv-->
        <div class="choose-lazy-section sh-sub-sectionWhite sh-sub-shiftTop" v-if="['fi', 'sv', 'en'].includes( curLocale )">
          <div class="sh-sub-diagonalTop"></div>
          <div class="pt-0 container sh-sub-sectionContainer maxWidth-1140">
            <h2 class="font-weight-light">{{ $t( 'message.NEWTitleLazy' ) }}</h2>
            <v-row>
              <v-col>
                <p>{{ $t( 'message.NEWTextLazyLine1' ) }}</p>
              </v-col>
            </v-row>
            <div class="eSigning-slogan">
              {{ $t( 'message.MetricsTitle' ) }}
            </div>
            <v-row class="mt-3">
              <v-col class="weekly-metrics" cols="12" sm="4">
                {{ $t( 'message.Metrics1' ) }}
              </v-col>
              <v-col class="weekly-metrics" cols="12" sm="4">
                {{ $t( 'message.Metrics2' ) }}
              </v-col>
              <v-col class="weekly-metrics" cols="12" sm="4">
                {{ $t( 'message.Metrics3' ) }}
              </v-col>
            </v-row>
          </div>
          <div class="sh-sub-diagonalTBottom"></div>
        </div>

        <!--section3 Account -->

        <a id="Account" class="sh-sub-anchor sh-sub-shiftAnchor"></a>
        <div class="sh-sub-sectionGrey">
          <div class="sh-sub-diagonalTop"></div>
          <div class="container text-center sh-sub-sectionContainer maxWidth-1140">
            <h2 class="font-weight-light">{{ $t( 'message.TitleAccount' ) }}</h2>
            <div class="row">
              <div class="col-12 sh-sub-subH1 sh-marginBottom">
                <p>{{ $t( 'message.TextAccount' ) }}</p>
              </div>
              <div class="col-md-4 sh-sub-subH1 sh-feature">
                <i class="fas fa-envelope"></i>
                <p>{{ $t( 'message.Feature1' ) }}</p>
              </div>
              <div class="col-md-4 sh-sub-subH1 sh-feature">
                <i class="fas fa-shoe-prints"></i>
                <p>{{ $t( 'message.Feature2' ) }}</p>
              </div>
              <div class="col-md-4 sh-sub-subH1 sh-feature">
                <i class="fas fa-bell"></i>
                <p>{{ $t( 'message.Feature3' ) }}</p>
              </div>
              <div class="col-md-4 sh-sub-subH1 sh-feature">
                <i class="fas fa-eye"></i>
                <p>{{ $t( 'message.Feature4' ) }}</p>
              </div>
              <div class="col-md-4 sh-sub-subH1 sh-feature">
                <i class="fas fa-users"></i>
                <p>{{ $t( 'message.Feature5' ) }}</p>
              </div>
              <div class="col-md-4 sh-sub-subH1 sh-feature">
                <i class="fas fa-archive"></i>
                <p>{{ $t( 'message.Feature6' ) }}</p>
              </div>
              <div class="col sh-sub-subH1 sh-marginTop">
                <p>
                  {{ $t( 'message.UnlimitedSigners' ) }}<br><br>
                  {{ $t( 'message.EasyRegister' ) }}<br>
                </p>
              </div>
            </div>
            <div class="sh-sub-callToAction landing-btn text-center">
              <v-btn class="white--text text-none" to="/signup" depressed>
                {{ $t( 'message.Register' ) }}
              </v-btn>
            </div>
            <div>
              <v-carousel class="sh-sub-referencesCarousel" cycle show-arrows-on-hover hide-delimiters>
                <v-carousel-item v-for="( reference, index ) in references" :key="index">
                  <v-col cols="12">
                    <v-row>
                      <v-img class="col-4 offset-4 col-sm-3 offset-sm-1 sh-sub-carouselIMG"
                             :src="reference.photo"></v-img>
                      <div class="col-12 col-sm-7 offset-sm-1 sh-sub-carouselReference">
                        {{ reference.quote }}
                        <div class="text-center text-md-right text-lg-right text-xl-right sh-sub-carouselQuoteAuthor">
                          {{ reference.name }}<br>
                          {{ reference.source }}
                        </div>
                      </div>
                    </v-row>
                  </v-col>
                </v-carousel-item>
              </v-carousel>
            </div>
          </div>
          <div class="sh-sub-diagonalTBottom"></div>
        </div>

        <!--section4 Pricing-->

        <a id="Pricing" class="sh-sub-anchor sh-sub-shiftAnchor"></a>

        <!--OLD. Used by de, es, fr, nl, pl, th-->
        <div class="sh-sub-sectionWhite" v-if="!['fi', 'sv', 'en'].includes( curLocale )">
          <div class="sh-sub-diagonalTop"></div>
          <div class="container text-center sh-sub-sectionContainer mb-12 maxWidth-600">
            <h2 class="font-weight-light">{{ $t( 'message.TitlePricing' ) }}</h2>
            <div class="row">
              <div class="col sh-sub-subH1">
                <p class="mb-2 monthly-price">{{ $t( 'message.MonthlyPrice' ) }}</p>
                <p class="font-italic bill-monthly">{{ $t( 'message.MonthlyBill' ) }}</p>
              </div>
            </div>
            <div class="my-6 pricing-benefits">
              <p><span class="mdi mdi-checkbox-marked-circle-outline pr-2"></span>{{ $t( 'message.Benefits1' ) }}</p>
              <p><span class="mdi mdi-checkbox-marked-circle-outline pr-2"></span>{{ $t( 'message.Benefits2' ) }}</p>
              <p><span class="mdi mdi-checkbox-marked-circle-outline pr-2"></span>{{ $t( 'message.Benefits3' ) }}</p>
              <p><span class="mdi mdi-checkbox-marked-circle-outline pr-2"></span>{{ $t( 'message.Benefits4' ) }}</p>
            </div>
            <div class="landing-btn pricing-btn pt-4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="white--text text-none" to="/signup" v-bind="attrs" v-on="on">
                    {{ $t( 'message.ButtonFreeTrial' ) }}
                  </v-btn>
                </template>
                <span>{{ $t( 'message.CreditCardInfo' ) }}</span>
              </v-tooltip>
            </div>
          </div>
          <div class="sh-sub-diagonalTBottom"></div>
        </div>

        <!--NEW. Used by en, fi, sv-->
        <div class="sh-sub-sectionWhite" v-if="['fi', 'sv', 'en'].includes( curLocale )">
          <div class="sh-sub-diagonalTop"></div>
          <div class="container text-center sh-sub-sectionContainer mb-12 maxWidth-650">
            <h2 class="font-weight-light">{{ $t( 'message.TitlePricing' ) }}</h2>
            <div class="row">
              <div class="col sh-sub-subH1">
                <p class="mb-2 monthly-price">{{ $t( 'message.NEWMonthlyPrice1' ) }}</p>
                <p class="mb-2 monthly-price">{{ $t( 'message.NEWMonthlyPrice2' ) }}</p>
                <p class="font-italic bill-monthly">{{ $t( 'message.MonthlyBill' ) }}</p>
              </div>
            </div>
            <div class="my-6 pricing-benefits">
              <p><span class="mdi mdi-checkbox-marked-circle-outline pr-2"></span>{{ $t( 'message.NEWBenefits1' ) }}</p>
              <p><span class="mdi mdi-checkbox-marked-circle-outline pr-2"></span>{{ $t( 'message.NEWBenefits2' ) }}</p>
              <p><span class="mdi mdi-checkbox-marked-circle-outline pr-2"></span>{{ $t( 'message.NEWBenefits3' ) }}</p>
              <p><span class="mdi mdi-checkbox-marked-circle-outline pr-2"></span>{{ $t( 'message.NEWBenefits4' ) }}</p>
            </div>
            <br/>
            <div class="row">
              <div class="col sh-sub-subH1">
                <p class="mb-2 monthly-price">{{ $t( 'message.NEWAdvancedPrice1' ) }}</p>
                <p class="mb-2 monthly-price">{{ $t( 'message.NEWAdvancedPrice2' ) }}</p>
                <p class="mb-2 monthly-price">{{ $t( 'message.NEWAdvancedPrice3' ) }}</p>
                <p class="mb-2 monthly-price">{{ $t( 'message.NEWAdvancedPrice4' ) }}</p>
                <p class="mb-2 monthly-price">{{ $t( 'message.NEWAdvancedPrice5' ) }}</p>
                <p class="mb-2 monthly-price">{{ $t( 'message.NEWAdvancedPrice6' ) }}</p>
                <p class="font-italic bill-monthly">{{ $t( 'message.NEWAdvancedBill' ) }}</p>
              </div>
            </div>
            <div class="my-6 pricing-benefits">
              <p><span class="mdi mdi-checkbox-marked-circle-outline pr-2"></span>{{ $t( 'message.NEWBenefits5' ) }}</p>
              <p><span class="mdi mdi-checkbox-marked-circle-outline pr-2"></span>{{ $t( 'message.NEWBenefits6' ) }}</p>
            </div>
            <div class="landing-btn pricing-btn pt-4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="white--text text-none" to="/signup" v-bind="attrs" v-on="on">
                    {{ $t( 'message.ButtonFreeTrial' ) }}
                  </v-btn>
                </template>
                <span>{{ $t( 'message.CreditCardInfo' ) }}</span>
              </v-tooltip>
            </div>
          </div>
          <div class="sh-sub-diagonalTBottom"></div>
        </div >

        <!--section5 News-->

        <a id="News" class="sh-sub-anchor sh-sub-shiftAnchor"></a>
        <div class="sh-sub-sectionGrey">
          <div class="sh-sub-diagonalTop"></div>
          <div class="container text-center sh-sub-sectionContainer maxWidth-1140">
            <h2 class="font-weight-light">{{ $t( 'message.TitleNews' ) }}</h2>
            <div>
              <v-carousel class="sh-sub-referencesCarousel" cycle show-arrows-on-hover hide-delimiters>
                <v-carousel-item v-for="( article, index ) in articles" :key="index"
                                 v-if="article.locales.includes( curLocale )">
                  <v-col cols="12">
                    <v-row>
                      <v-img class="col-4 offset-4 col-sm-3 offset-sm-1 sh-sub-carouselIMG" :src="article.photo"></v-img>
                      <div class="col-12 col-sm-7 offset-sm-1 sh-sub-carouselReference">
                        {{ article.title }}
                        <div class="text-center text-md-right text-lg-right text-xl-right sh-sub-carouselQuoteAuthor">
                          <a :href="article.link" target="_blank" class="read-more-link">{{ $t( 'message.ReadMoreLink' ) }}</a>
                        </div>
                      </div>
                    </v-row>
                  </v-col>
                </v-carousel-item>
              </v-carousel>
            </div>
          </div>
          <div class="sh-sub-diagonalTBottom"></div>
        </div>

        <!--section5 API & Integrations-->

        <a id="APIs" class="sh-sub-anchor sh-sub-shiftAnchor"></a>
        <div class="sh-sub-sectionWhite sh-sub-shiftTop">
          <div class="sh-sub-diagonalTop"></div>
          <div class="container text-center sh-sub-sectionContainer maxWidth-1140">
            <h2 class="font-weight-light">{{ $t( 'message.TitleAPI' ) }}</h2>
            <div class="row">
              <div class="col sh-sub-subH1">
                <p>{{ $t( 'message.IntegrateSignHero' ) }}</p>
              </div>
            </div>
            <div class="row api-button-group">
              <div class="landing-btn col-12 col-sm-12 col-md-6">
                <v-btn class="white--text text-none" href="/intro/api" target="_blank">
                  {{ $t( 'message.ToIntroBTN' ) }}
                </v-btn>
              </div>
              <div class="landing-btn col-12 col-sm-12 col-md-6">
                <v-btn class="white--text text-none" href="/docs/api" target="_blank">
                  {{ $t( 'message.ToDocsBTN' ) }}
                </v-btn>
              </div>
              <div class="landing-btn col-12 col-sm-12 col-md-6">
                <v-btn class="white--text text-none" href="/intro/gmail" target="_blank">
                  {{ $t( 'message.ToGmailBTN' ) }}
                </v-btn>
              </div>
              <div class="landing-btn col-12 col-sm-12 col-md-6">
                <v-btn class="white--text text-none" href="/intro/zapier" target="_blank">
                  {{ $t( 'message.ToZapierBTN' ) }}
                </v-btn>
              </div>
            </div>
          </div>
          <div class="sh-sub-diagonalTBottom"></div>
        </div>

        <!--section6 Doubts & Worries -->

        <a id="Doubts" class="sh-sub-anchor sh-sub-shiftAnchor" ></a>

        <!--OLD. Used by de, es, fr, nl, pl, th-->
        <div class="sh-sub-sectionGrey" v-if="!['fi', 'sv', 'en'].includes( curLocale )">
          <div class="sh-sub-diagonalTop"></div>
          <div class="container text-center sh-sub-sectionContainer maxWidth-1140">
            <h2 class="font-weight-light">{{ $t( 'message.TitleDoubts' ) }}</h2>
            <div class="container text-center pt-0">
              <div>
                <div class="sh-sub-doubtsQuestion">
                  <v-expansion-panels flat class="ma-auto">
                    <v-expansion-panel class="py-5">
                      <v-expansion-panel-header class="px-0 ma-auto doubts-panel-header">{{ $t( 'message.DoubtsLegal' ) }}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="sh-sub-doubtsAnswer">
                          <p class="answer-title">{{ $t( 'message.TitleShortAnswer' ) }}</p>
                          {{ $t( 'message.AnswerLegalParagraph1' ) }}<br>
                          <br>
                          {{ $t( 'message.AnswerLegalParagraph2' ) }}
                          <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32014R0910"
                             target="_blank" rel="noopener">{{ $t( 'message.eIDAS' ) }}</a> {{ $t( 'message.And' ) }}
                          <a href="https://www.gpo.gov/fdsys/pkg/PLAW-106publ229/pdf/PLAW-106publ229.pdf"
                             target="_blank" rel="noopener">{{ $t( 'message.ESIGN' ) }}</a> {{ $t( 'message.Acts' ) }} <br>
                          <br>
                          <p class="answer-title">{{ $t( 'message.TitleLongAnswer' ) }}</p>
                          {{ $t( 'message.LegalLongAnswerParagraph1' ) }}<br>
                          <br>
                          {{ $t( 'message.LegalLongAnswerParagraph3' ) }}<br>
                          <br>
                          <p class="answer-paragraph" v-html="$t( 'message.LegalLongAnswerParagraph4' )"></p>
                          <br>
                          <p class="answer-paragraph" v-html="$t( 'message.LegalLongAnswerParagraph5' )"></p>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel class="py-5">
                      <v-expansion-panel-header class="px-0 ma-auto doubts-panel-header">{{ $t( 'message.DoubtsPrice' ) }}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="sh-sub-doubtsAnswer">
                          <p class="answer-title">{{ $t( 'message.TitleShortAnswer' ) }}</p>
                          <p class="answer-paragraph" v-html="$t( 'message.PriceShortAnswerParagraph1' )"></p>
                          <br>
                          <p class="answer-title">{{ $t( 'message.TitleLongAnswer' ) }}</p>
                          <p class="answer-paragraph" v-html="$t( 'message.PriceLongAnswerParagraph1' )"></p>
                          <br>
                          <p class="answer-paragraph" v-html="$t( 'message.PriceLongAnswerParagraph2' )"></p>
                          <br>
                          <p class="answer-paragraph" v-html="$t( 'message.PriceLongAnswerParagraph3' )"></p>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </div>
            </div>
          </div>
          <div class="sh-sub-diagonalTBottom"></div>
        </div>

        <!--NEW. Used by en, fi, sv-->
        <div class="sh-sub-sectionGrey" v-if="['fi', 'sv', 'en'].includes( curLocale )">
          <div class="sh-sub-diagonalTop"></div>
          <div class="container text-center sh-sub-sectionContainer maxWidth-1140">
            <h2 class="font-weight-light">{{ $t( 'message.TitleDoubts' ) }}</h2>
            <div class="container text-center pt-0">
              <div>
                <div class="sh-sub-doubtsQuestion">
                  <v-expansion-panels flat class="ma-auto">
                    <v-expansion-panel class="py-5">
                      <v-expansion-panel-header class="px-0 ma-auto doubts-panel-header">{{ $t( 'message.NEWDoubtsLegal' ) }}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="sh-sub-doubtsAnswer">
                          {{ $t( 'message.NEWLegalLongAnswerParagraph1' ) }}<br>
                          <br>
                          {{ $t( 'message.NEWLegalLongAnswerParagraph2' ) }}<br>
                          <br>
                          {{ $t( 'message.NEWLegalLongAnswerParagraph3' ) }}<br>
                          <br>
                          <p class="answer-paragraph" v-html="$t( 'message.NEWLegalLongAnswerParagraph4' )"></p>
                          <br>
                          <p class="answer-paragraph" v-html="$t( 'message.NEWLegalLongAnswerParagraph5' )"></p>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel class="py-5">
                      <v-expansion-panel-header class="px-0 ma-auto doubts-panel-header">{{ $t( 'message.NEWDoubtsPrice' ) }}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="sh-sub-doubtsAnswer">
                          <p class="answer-paragraph" v-html="$t( 'message.NEWPriceLongAnswerParagraph1' )"></p>
                          <br>
                          <p class="answer-paragraph" v-html="$t( 'message.NEWPriceLongAnswerParagraph2' )"></p>
                          <br>
                          <p class="answer-paragraph" v-html="$t( 'message.NEWPriceLongAnswerParagraph3' )"></p>
                          <br>
                          <p class="answer-paragraph" v-html="$t( 'message.NEWPriceLongAnswerParagraph4' )"></p>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </div>
            </div>
          </div>
          <div class="sh-sub-diagonalTBottom"></div>
        </div>

        <!--section7 This is us -->

        <a id="ThisIsUs" class="sh-sub-anchor sh-sub-shiftAnchor"></a>
        <div class="sh-sub-sectionWhite">
          <div class="sh-sub-diagonalTop"></div>
          <div class="container text-center text-md-right text-lg-right text-xl-right  sh-sub-sectionContainer maxWidth-1140">
            <h2 class="font-weight-light">{{ $t( 'message.TitleThisIsUs' ) }}</h2>
            <div class="sh-sub-thisIsUsLink text-center">
              <span><a href="/news" target="_blank">{{ $t( 'message.TitleNews' ) }}</a></span>
            </div>
            <div class="sh-sub-thisIsUsLink text-center">
              <span><a href="/history.html" target="_blank">{{ $t( 'message.History' ) }}</a></span>
            </div>
            <div class="sh-sub-thisIsUsLink text-center">
              <a href="https://www.linkedin.com/company/signhero" target="_blank" rel="noopener">{{ $t( 'message.LinkedIn' ) }}</a>
            </div>
            <div class="sh-sub-thisIsUsLink text-center">
              <a href="https://www.youtube.com/channel/UC8m3a2z7WjYCMHCcValbfwQ" target="_blank" rel="noopener">{{ $t( 'message.Youtube' ) }}</a>
            </div>
          </div>
          <div class="sh-sub-diagonalTBottom"></div>
        </div>

        <!--Contact us/ Landing page's footer-->

        <a id="ContactUs" class="sh-sub-anchor sh-sub-shiftAnchor"></a>
        <div class="sh-sub-footer">
          <div class="sh-sub-diagonalTop"></div>
          <div class="container sh-sub-sectionContainer maxWidth-1140">
            <div class="row">
              <div class="col-sm-4 text-center text-sm-left text-lg-left text-xl-left sh-sub-footerLeft">
                <div class="sh-sub-logoFooter">
                  <a href="https://www.avaintec.com/">
                    <img src="../../../public/AvaintecLogo.svg">
                  </a>
                </div>
                <div class="sh-sub-footerText">
                  {{ $t( 'message.CompanyName' ) }}<br>
                  {{ $t( 'message.CompanyAddress' ) }}<br>
                  <a href="https://www.avaintec.com/" target="_blank" rel="noopener">{{ $t( 'message.CompanyWeb' ) }}</a>
                </div>
              </div>
              <div class="col-sm-4 sh-sub-footerLinks">
                <h4 class="font-weight-light text-center">{{ $t( 'message.TitleMoreQuestion' ) }}</h4>
                <a href="mailto:hello@signhero.io" class="text-center">{{ $t( 'message.SignHeroEmail' ) }}</a>
              </div>
              <div class="col-sm-4 text-center text-sm-right text-lg-right text-xl-right sh-sub-footerRight">
                <div class="sh-sub-footerLinks">
                  <a href="/docs/api" target="_blank">
                    {{ $t( 'message.ApiDocsLink' ) }}
                  </a>
                  <a href="/terms-of-use.html" id="termsOfUsageLink" target="_blank">
                    {{ $t( 'message.TermOfUseLink' ) }}
                  </a>
                  <a href="/privacy-policy.html" target="_blank">
                    {{ $t( 'message.PrivacyPolicyLink' ) }}
                  </a>
                </div>
                <div class="sh-sub-footerCopy">
                  {{ $t( 'message.CopyrightYear' ) }}<a href="https://www.avaintec.com/" target="_blank" rel="noopener">
                  {{ $t( 'message.CompanyOyName' ) }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="sh-dividerFooterBorder"></div>
          <div class="container sh-sub-sectionContainer sh-finalFooter">
            <div>{{ $t( 'message.CopyrightYear' ) }}{{ $t( 'message.RegulationNotice' ) }}
              <a href="https://en.wikipedia.org/wiki/Electronic_Signatures_in_Global_and_National_Commerce_Act"
                 target="_blank" rel="noopener">{{ $t( 'message.ESignAct' ) }}</a> {{ $t( 'message.And' ) }} <a
                  href="https://en.wikipedia.org/wiki/EIDAS" target="_blank" rel="noopener">
                {{ $t( 'message.eIDASRegulations' ) }}
              </a>.
            </div>
          </div>
        </div>
      </v-col>
      <v-col v-if="choose_language_setting">
        <div class="ma-auto mt-1 maxWidth-1100 pa-6">
          <h1 class="shio-section-title">{{ $t( 'message.Locales' ) }}</h1>
          <v-radio-group v-model="locale" class="mx-3 mt-6">
            <v-radio v-for="locale in locales" :key="locale" :label="$t( 'message.locale!' + locale )" :value="locale"></v-radio>
          </v-radio-group>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <div class="choose-language-btn mt-5 mx-3">
                <v-btn class="primary-btn" @click="setLocale( locale )">{{ $t( 'signflow.Ok' ) }}</v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
    import util from "../../util/util";
    /**
     * Lookup the available locales from i18n.
     */
    const locales = [ "en", "fi", "sv", "pl", "de", "nl", "fr", "es", "th" ];
    export default {
        name : "LandingPane",
        data()
        {
            return {
                drawer : null,
                group : null,
                references : [
                    {
                        quote : this.$t( 'message.QuoteMarko' ),
                        name : this.$t( 'message.AuthorMarko' ),
                        source : this.$t( 'message.CompanyMarko' ),
                        photo : require( '../../../public/references/vilpas.jpg' ),
                    },
                    {
                        quote : this.$t( 'message.QuoteHenri' ),
                        name : this.$t( 'message.AuthorHenri' ),
                        source : this.$t( 'message.CompanyHenri' ),
                        photo : require( '../../../public/references/aaltoes.jpg' ),
                    },
                    {
                        quote : this.$t( 'message.QuoteJuho' ),
                        name : this.$t( 'message.AuthorJuho' ),
                        source : this.$t( 'message.CompanyJuho' ),
                        photo : require( '../../../public/references/sportspot.jpg' ),
                    },
                    {
                        quote : this.$t( 'message.QuoteKarolina' ),
                        name : this.$t( 'message.AuthorKarolina' ),
                        source : this.$t( 'message.CompanyKarolina' ),
                        photo : require( '../../../public/references/legal_advisers_KBL.jpg' ),
                    },
                    {
                        quote : this.$t( 'message.QuoteMatias' ),
                        name : this.$t( 'message.AuthorMatias' ),
                        source : this.$t( 'message.CompanyMatias' ),
                        photo : require( '../../../public/references/Koju.jpg' ),
                    },
                    {
                        quote : this.$t( 'message.QuoteKalle' ),
                        name : this.$t( 'message.AuthorKalle' ),
                        source : this.$t( 'message.CompanyKalle' ),
                        photo : require( '../../../public/references/fluxio.jpg' ),
                    },
                    {
                        quote : this.$t( 'message.QuoteMonika' ),
                        name : this.$t( 'message.AuthorMonika' ),
                        source : this.$t( 'message.CompanyMonika' ),
                        photo : require( '../../../public/references/dogstation.jpg' ),
                    },
                    {
                        quote : this.$t( 'message.QuoteDominika' ),
                        name : this.$t( 'message.AuthorDominika' ),
                        source : this.$t( 'message.CompanyDominika' ),
                        photo : require( '../../../public/references/mdj.jpg' ),
                    },
                    {
                        quote : this.$t( 'message.QuoteVisa' ),
                        name : this.$t( 'message.AuthorVisa' ),
                        source : this.$t( 'message.CompanyVisa' ),
                        photo : require( '../../../public/references/LehmusRoastery.jpg' ),
                    },
                    {
                        quote : this.$t( 'message.QuoteOlli' ),
                        name : this.$t( 'message.AuthorOlli' ),
                        source : this.$t( 'message.CompanyOlli' ),
                        photo : require( '../../../public/api-references/webaula.jpg' ),
                    },
                ],
                articles : [
                    {
                        title : this.$t( 'message.NewsTitleCertMe' ),
                        photo : require( '../../../public/news-images/CertMe.jpg' ),
                        link : "/news/finnish-e-signing-service-signhero-integrates-romanian-certsign",
                        locales : [ "en", "fi", "sv", "pl", "de", "nl", "fr", "es", "th" ]
                    },
                    {
                        title : this.$t( 'message.NewsTitlePartnerStory' ),
                        photo : require( '../../../public/news-images/PartnerStory.jpg' ),
                        link : "/news/signhero-partner-story-proactive-cooperation-offers-a-win-win-win-situation",
                        locales : [ "en", "fi", "sv", "pl", "de", "nl", "fr", "es", "th" ]
                    },
                    {
                        title : this.$t( 'message.NewsTitleSaveTime' ),
                        photo : require( '../../../public/news-images/SaveTime.jpg' ),
                        link : "/news/more-secure-way-to-sign-gives-you-more-time-and-money",
                        locales : [ "en", "fi", "sv", "pl", "de", "nl", "fr", "es", "th" ]
                    },
                    {
                        title : this.$t( 'message.NewsTitleElectronicSignature' ),
                        photo : require( '../../../public/news-images/ElectronicSignature.jpg' ),
                        link : "https://www.tivi.fi/uutiset/nain-toimii-sahkoinen-allekirjoitus-testissa-6-ratkaisua/4762d0c7-6382-47a2-907b-0fec506f4b9d",
                        locales : [ "fi" ]
                    },
                    {
                      title : this.$t( 'message.NewsTitleSahihiHaki' ),
                      photo : require( '../../../public/news-images/SignHeroSahihiHaki.jpg' ),
                      link : "/news/sahihi-haki-signhero-signed-a-joint-venture-agreement",
                      locales : [ "en", "fi", "sv", "pl", "de", "nl", "fr", "es", "th" ]
                    },
                    {
                      title : this.$t( 'message.NewsTitlePrivyPartner' ),
                      photo : require( '../../../public/news-images/SignHeroPrivyPartner.jpg' ),
                      link : "/news/privy-partner-and-avaintec-bring-signhero-to-the-southeast-asian-market-area",
                      locales : [ "en", "fi", "sv", "pl", "de", "nl", "fr", "es" ]
                    },
                    {
                      title : this.$t( 'message.NewsTitleSmartID' ),
                      photo : require( '../../../public/news-images/SmartID.jpg' ),
                      link : "/news/signHero-now-supports-smart-id-for-secure-esigning-across-the-baltics-and-belgium",
                      locales : [ "en", "fi", "sv", "pl", "de", "nl", "fr", "es" ]
                    },
                ],
                locale : util.getLocale(),
                locales : locales,
                choose_language_setting : false
            }
        },
        watch : {
            group()
            {
                this.drawer = false
            },
        },
        computed : {
            curLocale : util.getLocale
        },
        methods : {
            /**
             * Change locale to newLoc. This involves a page reload.
             *
             * @param newLoc
             */
            setLocale( newLoc )
            {
                util.setLocale( newLoc );
            },
            showLanguageSetting()
            {
              this.locale = util.getLocale();
              this.choose_language_setting = !this.choose_language_setting;
            },
            closeLanguageSetting()
            {
                this.choose_language_setting = false;
            }
        }
    }
</script>

<style lang="sass" scoped>
  @import "../../styles/style"

  /* layout */

  .maxWidth-1140
    max-width: 1140px

  /* header */

  .signout-header
    background: $background-black !important
    font-size: 18px

  /* drawer */

  .right-nav
    top: 64px !important
    max-height: calc(100vh - 64px) !important
    position: fixed
    background-color: $background-black

  .landing-nav-btn.v-btn.v-btn--text.theme--light.v-size--default.white--text:before
    background-color: $background-black !important

  .right-nav .v-list-item-group
    color: $vivid-pink

  .ma-0.pa-3.v-list-item.v-item--active.v-list-item--active.v-list-item--link.theme--light:before
    background-color: $background-black !important

  .ma-0.pa-3.v-list-item.v-list-item--link.theme--light:hover:before
    background-color: $background-black !important

  .v-list-item__title.drawer-title:hover
    color: $vivid-pink !important

  .v-list-item__title.drawer-title:hover:before
    background-color: $background-black !important

  .right-nav .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
    color: white !important

  .v-list-item--dense .v-list-item__title, .v-list-item--dense .v-list-item__subtitle, .v-list--dense .v-list-item .v-list-item__title, .v-list--dense .v-list-item .v-list-item__subtitle
    font-size: 1.1em

  .right-nav .v-list-item__title.drawer-title
    line-height: 1.5rem !important

  /* Join now */

  .sh-sub-banner
    min-height: calc(100vh - 70px)
    color: white
    background: $background-black
    background-image: url('public/bg.jpg')
    background-repeat: no-repeat
    background-size: cover
    background-position: center
    text-align: center
    overflow: hidden
    padding: 8vh 15px
    display: flex

  .sh-fp-intro
    font-size: 28px

  .margin-above-joinNow
    margin-bottom: 100px

  .sh-sub-floatingContainer
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    text-align: center
    margin-top: -6vh

  .slogan
    color: $vivid-pink
    font-size: 46px
    font-weight: 300
    text-align: center
    margin-bottom: 25px

  .landing-btn .v-btn
    background: $vivid-pink !important
    font-size: 22px
    border-radius: 10px
    padding: 25px 40px
    line-height: 1.5
    min-width: 200px

  @media(min-width: 601px)
    .landing-btn .v-btn
      min-width: 270px

  .landing-btn .v-btn:hover
    background: $dark-grey !important

  h2
    font-size: 58px
    text-align: center
    margin-bottom: 100px

  p
    font-size: 24px
    font-weight: 300

  .eSigning-slogan
    color: $vivid-pink
    font-size: 34px

  a.sh-sub-anchor
    display: block
    position: relative
    top: -70px
    visibility: hidden

  a.sh-sub-anchor.sh-sub-shiftAnchor
    top: -12px

  .sh-sub-shiftTop .sh-sub-diagonalTop
    top: -8vh

  .sh-sub-diagonalTop
    -webkit-transform: skewY(-2deg)
    -moz-transform: skewY(-2deg)
    -ms-transform: skewY(-2deg)
    -o-transform: skewY(-2deg)
    transform: skewY(-2deg)
    padding-top: 12vh
    border-top: 5px solid $vivid-pink
    margin: 0 -15px
    position: relative
    z-index: 1

  .sh-sub-diagonalTBottom
    -webkit-transform: skewY(-2deg)
    -moz-transform: skewY(-2deg)
    -ms-transform: skewY(-2deg)
    -o-transform: skewY(-2deg)
    transform: skewY(-2deg)
    padding-bottom: 12vh
    margin: 0 -15px
    position: relative
    z-index: 1

  /* Choose easy */

  .weekly-metrics
    font-size: 22px
    color: $vivid-pink

  .choose-lazy-section h2
    margin-bottom: 25px !important

  /* Video */

  .sh-fp-video
    color: white
    background: $background-black
    background-repeat: no-repeat
    background-size: cover
    background-position: center
    text-align: center
    background-image: url('public/ChooseLazy.jpg')
    -webkit-transform: skewY(-2deg)
    -moz-transform: skewY(-2deg)
    -ms-transform: skewY(-2deg)
    -o-transform: skewY(-2deg)
    transform: skewY(-2deg)

  .sh-fp-video .sh-sub-diagonalTop,
  .sh-fp-video .sh-sub-diagonalTBottom
    -webkit-transform: skewY(0deg)
    -moz-transform: skewY(0deg)
    -ms-transform: skewY(0deg)
    -o-transform: skewY(0deg)
    transform: skewY(0deg)

  .sh-fp-video::before
    content: ""
    background: linear-gradient(180deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 0) 100%)
    height: 100px
    width: 100%
    display: block
    position: absolute
    left: 0

  .sh-fp-video::after
    content: ""
    background: linear-gradient(0deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 0) 100%)
    height: 100px
    width: 100%
    display: block
    position: absolute
    left: 0
    bottom: 0

  .videoPlayBTN i
    font-size: 60px
    color: $vivid-pink
    -webkit-transition: all 100ms linear
    -moz-transition: all 100ms linear
    -o-transition: all 100ms linear
    -ms-transition: all 100ms linear
    transition: all 100ms linear

  .videoPlayBTN :hover
    color: white

  /* Account */

  .sh-sub-sectionWhite
    background: white
    color: $text-black

  .sh-sub-sectionWhite .sh-sub-diagonalTop,
  .sh-sub-sectionWhite .sh-sub-diagonalTBottom
    background: white

  .sh-feature
    margin: 30px 0

  .sh-feature i
    display: block
    color: $vivid-pink
    font-size: 35px
    margin-bottom: 15px

  .sh-sub-callToAction
    padding-bottom: 75px
    margin: 30px 0
    border-bottom: 1px solid $dark-grey

  .sh-sub-apiReferencesCarousel, .sh-sub-referencesCarousel
    height: 180px !important

  .sh-sub-carouselReference
    color: white
    text-align: left
    font-style: italic
    font-size: 22px
    align-items: inherit

  .sh-sub-carouselQuoteAuthor
    font-style: normal
    font-size: 16px
    margin-top: 10px

  .sh-sub-carouselIMG
    max-height: 150px
    max-width: 150px
    border-radius: 30px
    display: inline-block
    width: 100%
    height: auto

  .sh-sub-sectionGrey
    color: white
    background: $text-black

  .sh-feature p
    color: $mid-grey

  .sh-sub-sectionGrey .sh-sub-diagonalTop,
  .sh-sub-sectionGrey .sh-sub-diagonalTBottom
    background: $text-black

  .sh-sub-sectionGrey h2
    color: white
    font-size: 58px
    text-align: center
    margin-bottom: 100px

  .sh-sub-carouselAPI
    text-align: left
    font-style: italic
    font-size: 22px
    align-items: inherit
    color: $text-black

  /* Pricing */

  .bill-monthly
    font-size: 18px

  /* Doubts & Worries */

  .maxWidth-600
    max-width: 600px

  .v-expansion-panels
    z-index: auto

  .v-expansion-panel
    background-color: transparent !important

  .v-expansion-panel button
    color: $vivid-pink
    font-size: 2rem
    font-style: italic
    text-decoration: none
    padding-bottom: 5px
    border-bottom: 2px solid white

  .sh-sub-doubtsAnswer
    padding-top: 25px
    color: white

  .answer-title
    color: $mid-grey
    font-size: 16px

  .answer-paragraph
    font-size: 16px

  .sh-sub-doubtsAnswer a
    color: $vivid-pink
    text-decoration: none
    border-bottom: 1px solid white

  .sh-sub-doubtsAnswer a:hover
    color: white

  .doubts-panel-header
    max-width: max-content

  /* News */

  .read-more-link
    text-decoration: underline

  /* This is us */

  .sh-sub-thisIsUsLink
    padding: 25px

  .sh-sub-thisIsUsLink a
    font-size: 2rem
    font-style: italic
    color: $vivid-pink
    text-decoration: none
    padding-bottom: 5px
    border-bottom: 2px solid $text-black

  .sh-sub-thisIsUsLink a:hover
    color: $text-black

  /* Contact us & Footer */

  .sh-sub-footer
    color: $mid-grey
    background: $background-black

  .sh-sub-footer .sh-sub-diagonalTop
    background: $background-black
    padding-top: 4%

  .sh-sub-logoFooter img
    height: 55px
    width: 140px

  .sh-sub-sectionContainer
    margin-bottom: 0
    padding-bottom: 25px

  .sh-sub-footer a
    text-decoration: none

  .sh-sub-footer h4
    font-size: 1.5rem

  .sh-sub-footerLinks a
    display: block
    color: $vivid-pink

  .sh-sub-footerLinks a i
    color: white
    vertical-align: text-bottom

  .sh-sub-footerLinks a:hover
    color: white
    text-decoration: underline

  .sh-sub-footerCopy
    color: $mid-grey
    font-size: 16px
    margin-top: 30px

  .sh-dividerFooterBorder
    display: block
    border: none
    color: $mid-grey
    height: 1px
    background: $background-black
    background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 550, from($dark-grey), to($background-black))
    margin-top: 50px

  .sh-finalFooter
    font-size: 14px

  .sh-sub-footerLeft a,
  .sh-sub-footerCopy a,
  .sh-finalFooter a
    color: white

  .sh-sub-footerLeft a:hover,
  .sh-sub-footerCopy a:hover,
  .sh-finalFooter a:hover
    color: $mid-grey
    text-decoration: underline

  .api-button-group
    padding-bottom: 50px
    margin: 30px 0

  .choose-language-btn
    text-align: left

  /* Media */

  @media (max-height: 550px), (max-width: 767.99px)
    .sh-sub-diagonalTop,
    .sh-sub-diagonalTBottom,
    .sh-sub-diagonal,
    .sh-sub-diagonal > .container,
    .sh-fp-video
      -webkit-transform: skewY(0deg)
      -moz-transform: skewY(0deg)
      -ms-transform: skewY(0deg)
      -o-transform: skewY(0deg)
      transform: skewY(0deg)

    .sh-sub-floatingContainer
      margin-top: 0

    a.sh-sub-anchor.sh-sub-shiftAnchor,
    a.sh-sub-anchor.sh-sub-secondAnchor
      top: 0

    .sh-sub-shiftTop .sh-sub-diagonalTop
      padding-top: 8vh

    .sh-sub-shiftTop .sh-sub-diagonalTop
      top: 0

    .sh-sub-shiftTop .sh-sub-sectionContainer
      margin-top: 25px

  /* MD */

  @media (max-width: 1263px)
    .sh-sub-apiReferencesCarousel, .sh-sub-referencesCarousel
      height: 200px !important

  /* SM */

  @media (max-width: 959px)
    .sh-sub-apiReferencesCarousel, .sh-sub-referencesCarousel
      height: 260px !important

    .sh-sub-carouselReference, .sh-sub-carouselAPI
      text-align: center

  /* XS */

  @media (max-width: 599px)
    .sh-sub-apiReferencesCarousel, .sh-sub-referencesCarousel
      height: 420px !important

    .sh-sub-carouselReference, .sh-sub-carouselAPI
      text-align: center

    .landing-container
      display: -webkit-box

    .display-xs-none
      display: none

    .slogan
      font-size: 32px
      margin-bottom: 12px

    .sh-fp-intro
      font-size: 22px

    .landing-btn .v-btn
      font-size: 16px
      padding: 14px 20px
      border-radius: 4px

    h2
      font-size: 30px
      margin-bottom: 25px

    p
      font-size: 16px

    .eSigning-slogan
      font-size: 22px

    .weekly-metrics
      font-size: 18px

    .sh-sub-sectionGrey h2
      font-size: 30px
      margin-bottom: 25px

    .sh-feature
      margin: 10px 0

    .sh-sub-carouselReference
      font-size: 18px

    .bill-monthly
      font-size: 16px

    .monthly-price, .pricing-benefits p
      font-size: 18px

    .v-expansion-panel button, .sh-sub-thisIsUsLink a
      font-size: 22px

    .choose-language-btn
      text-align: center
</style>

<style lang="sass">
  .v-main
    padding-top: 64px !important

  aside.right-nav.v-navigation-drawer
    width: 265px !important

    /* SM */

  @media (max-width: 599px)

    .pricing-btn .v-btn__content
      white-space: normal
      flex: auto

  /* LG */

  @media (min-width: 1265px)

    .doubts-break1::after
      content: "\a"
      white-space: pre
</style>
